#button-go-quizz{
    transition: all .4s ease;
    border-color: #ACBFA4;
    color: #262626;
    position: relative;
}

#button-go-quizz:hover{
    background-color: #ACBFA4;
    border-color: #262626;
}